<template>
    <figure v-if="image">
        <ImageSet
            v-slot="{ height, width, src, srcset, avifSrc, avifSrcset, webpSrc, webpSrcset }"
            :image="image"
        >
            <picture
                v-motion
                class="base-image"
                :class="wrapperClass"
                :initial="fadeInView ? {
                    opacity: 0,
                    y: 0,
                    scale: 1.05
                } : null"
                :visible="fadeInView ? {
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    transition: {
                        duration: 1000,
                        ease: '0.4, 0, 0.2, 1',
                        delay: 0
                    }
                } : null"
            >
                <source
                    v-if="avifSrc || avifSrcset"
                    type="image/avif"
                    :src="avifSrc"
                    :sizes="sizes"
                    :srcset="avifSrcset"
                    :width="width"
                    :height="height"
                >
                <source
                    v-if="webpSrc || webpSrcset"
                    type="image/webp"
                    :src="webpSrc"
                    :sizes="sizes"
                    :srcset="webpSrcset"
                    :width="width"
                    :height="height"
                >
                <img
                    class="base-image__image"
                    :style="style"
                    :src="src"
                    :sizes="sizes"
                    :srcset="srcset"
                    :alt="imageAlt || ''"
                    :loading="loading"
                    :width="width"
                    :height="height"
                    :decoding="decoding"
                    :fetchpriority="fetchpriority"
                    v-bind="imageAttrs"
                >
            </picture>
        </ImageSet>

        <figcaption
            v-if="!!$slots.caption || image.caption || image.credits"
            class="base-image__caption"
        >
            <slot name="caption">
                <span v-if="image.caption" v-html="image.caption" /> <!-- eslint-disable-line -->
                <span v-if="image.credits && image.caption"> - </span>
                <span v-if="image.credits" v-html="image.credits" /> <!-- eslint-disable-line -->
            </slot>
        </figcaption>
    </figure>
</template>

<script>
import ImageSet from './ImageSet.vue';

export default {
    components: {
        ImageSet
    },
    props: {
        image: {
            type: Object,
            required: true
        },
        sizes: {
            type: String,
            default: '(min-width: 768px) 1020px, 100vw'
        },
        loading: {
            type: String,
            default: 'lazy',
            validator: value => ['lazy', 'eager'].includes(value)
        },
        decoding: {
            type: String,
            default: 'auto',
            validator: value => ['sync', 'async', 'auto'].includes(value)
        },
        fetchpriority: {
            type: String,
            default: 'auto',
            validator: value => ['high', 'low', 'auto'].includes(value)
        },
        imageAlt: {
            type: String,
            default: ''
        },
        wrapperClass: {
            type: String,
            default: ''
        },
        imageAttrs: {
            type: Object,
            required: false,
            default: null
        },
        fadeInView: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        hasFocalPoint() {
            return this.image?.hasFocalPoint ?? false;
        },
        focalPoint() {
            return this.image?.focalPoint;
        },
        objectFitPositionX() {
            return (this.focalPoint?.[0] ?? 0) * 100;
        },
        objectFitPositionY() {
            return (this.focalPoint?.[1] ?? 0) * 100;
        },
        objectFitPosition() {
            return `${this.objectFitPositionX}% ${this.objectFitPositionY}%`;
        },
        style() {
            return {
                'object-position': this.hasFocalPoint ? this.objectFitPosition : null
            };
        }
    }
};
</script>

<style src="./BaseImage.less" lang="less" />
